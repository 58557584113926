export default {
  methods: {
    isAboutToStart () {
      const duration = this.appointment.service.duration || this.appointment.service.service_type.duration
      const before = this.$moment(this.appointment.starts_at).subtract(15, 'minutes')
      const after = this.$moment(this.appointment.starts_at).add(duration, 'minutes')

      return this.$moment().isBetween(before, after)
    },
    alreadyStarted () {
      return this.$moment().isAfter(this.$moment(this.appointment.starts_at))
    },
    itsOver () {
      const duration = this.appointment.service.duration || this.appointment.service.service_type.duration
      return this.$moment().isAfter(this.$moment(this.appointment.starts_at).add(duration, 'minutes'))
    }
  },
  computed: {
    status () {
      const inPersonAllowedStatus = [
        'IN_PROGRESS',
        'CONFIRM'
      ]

      if (!this.appointment.virtual && !this.itsOver() && inPersonAllowedStatus.includes(this.appointment.status) && this.alreadyStarted()) {
        return 'IN_PROGRESS'
      }

      return this.appointment.status
    }
  }
}

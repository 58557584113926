var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageContainer',{attrs:{"title":_vm.$translations.appointments['appointment-details'].title,"back":_vm.actions.back},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.loading.appointment)?_c('div',{staticClass:"page-segment"},[_c('div',{staticClass:"page-segment-content"},[_c('Skeleton',{attrs:{"width":"100px","height":"19.2px","borderRadius":"16px"}}),_c('Skeleton',{attrs:{"margin":"16px 0","width":"100%","height":"96px","borderRadius":"16px"}})],1)]):_vm._e(),(!_vm.loading.appointment && _vm.appointment)?_c('div',{staticClass:"page-segment"},[_c('div',{staticClass:"page-segment-content"},[_c('Heading',{attrs:{"text":_vm.$translations['appointment-summary'].specialist}}),(_vm.appointment.service.service_type.type === 'ORIENTATION')?_c('PersonCard',{staticClass:"margin-y",attrs:{"title":_vm.specialistNameText,"image":_vm.appointment.specialist.user.profile_picture,"middle-line":_vm.appointment.specialist.type.name,"bottom-line":_vm.specialistAddressText}}):_c('PersonCard',{directives:[{name:"selectable-container",rawName:"v-selectable-container",value:({
            callback: _vm.navigateToSpecialistProfile,
            disable: _vm.$route.query.origin === 'shared-account'
          }),expression:"{\n            callback: navigateToSpecialistProfile,\n            disable: $route.query.origin === 'shared-account'\n          }"}],staticClass:"margin-y",attrs:{"title":_vm.specialistNameText,"image":_vm.appointment.specialist.user.profile_picture,"middle-line":_vm.appointment.specialist.type.name,"bottom-line":_vm.specialistAddressText,"show-action":_vm.$route.query.origin !== 'shared-account'}}),_c('Heading',{attrs:{"text":_vm.$translations['appointment-summary'].detail}}),_c('div',{staticClass:"appointment-service-card"},[_c('div',{staticClass:"appointment-service-card-icon",class:_vm.serviceIcon.iconName,style:({
              background: _vm.serviceIcon.background,
              color: _vm.serviceIcon.color
            })}),_c('div',{staticClass:"appointment-service-card-title heading-small",domProps:{"textContent":_vm._s(_vm.appointment.service.service_type.name)}}),_c('div',{staticClass:"appointment-service-card-status content-title",class:{
              'appointment-service-card-status-danger':
                _vm.mainAction[_vm.appointment.status].redStatus
            },domProps:{"textContent":_vm._s(_vm.$translations.status[_vm.status])}})]),_c('div',{staticClass:"appointment-stats"},[_c('StatItem',{attrs:{"text":_vm.date.day,"title":_vm.date.fullDate,"subTitle":_vm.date.time}}),_c('StatItem',{attrs:{"icon":"icon-timer-off","title":_vm.$translations.form.duration,"subTitle":`${_vm.appointment.service.service_type.duration} min`}}),_c('StatItem',{attrs:{"icon":"icon-video-outline-off","title":_vm.$translations.form['service-type'],"subTitle":_vm.$translations.appointments.virtual[_vm.appointment.virtual]}}),(_vm.allowedByRole([_vm.roleType.USER, _vm.roleType.USER_B2B]))?_c('StatItem',{attrs:{"icon":"icon-money-off","title":_vm.$translations.form.price,"subTitle":_vm.price,"extraData":_vm.label}}):_vm._e()],1),_c('div',{staticClass:"appointment-actions"},[(_vm.showMainAction)?_c('Button',{attrs:{"text":_vm.mainAction[_vm.appointment.status].text,"disable":_vm.disable,"loading":_vm.appointment.status === 'PROCESSING_PAYMENT',"icon":_vm.mainAction[_vm.appointment.status].icon},on:{"clicked":function($event){return _vm.handleMainAction(_vm.mainAction[_vm.appointment.status].action)}}}):_vm._e(),(_vm.showRescheduleAndCancel)?_c('div',{staticClass:"appointment-actions-secondary"},[_c('Button',{attrs:{"text":_vm.$translations.buttons.reschedule,"disable":_vm.rescheduleDisabled,"type":"outline","color":"gray","icon":"icon-timer-calendar-off"},on:{"clicked":_vm.handleReschedule}}),_c('Button',{attrs:{"text":_vm.$translations.buttons.cancel,"disable":_vm.cancelDisabled,"type":"outline","color":"red"},on:{"clicked":_vm.handleCancel}})],1):_vm._e()],1),_c('div',{staticClass:"hint"},[(_vm.showHint)?[_c('span',{staticClass:"small",domProps:{"innerHTML":_vm._s(
                _vm.$translations.appointments['appointment-details'].terms[0]
              )}}),_c('span',{staticClass:"link small text-purple cursor-pointer",domProps:{"innerHTML":_vm._s(
                _vm.$translations.appointments['appointment-details'].terms[1]
              )},on:{"click":_vm.openTermsAndConditions}})]:_vm._e(),(_vm.showCanCancel)?[_c('span',{staticClass:"small",domProps:{"innerHTML":_vm._s(
                _vm.$translations.appointments['appointment-details'][
                  'can-cancel'
                ]
              )}})]:_vm._e(),(_vm.showCallSupport)?[_c('span',{staticClass:"small",domProps:{"innerHTML":_vm._s(
                _vm.$translations.appointments['appointment-details'][
                  'call-support'
                ][0]
              )}}),_c('span',{staticClass:"link small text-purple cursor-pointer",domProps:{"innerHTML":_vm._s(
                _vm.$translations.appointments['appointment-details'][
                  'call-support'
                ][1]
              )},on:{"click":_vm.openKustomer}})]:_vm._e(),(_vm.showTermsAndConditions)?[_c('span',{staticClass:"small",domProps:{"innerHTML":_vm._s(_vm.$translations['appointment-pages'].summary.terms[0])}}),_c('span',{staticClass:"link small text-purple cursor-pointer",domProps:{"innerHTML":_vm._s(_vm.$translations['appointment-pages'].summary.terms[1])},on:{"click":_vm.openTermsAndConditions}}),_c('span',{staticClass:"small",domProps:{"innerHTML":_vm._s(_vm.$translations['appointment-pages'].summary.terms[2])}}),_c('span',{staticClass:"link small text-purple cursor-pointer",domProps:{"innerHTML":_vm._s(_vm.$translations['appointment-pages'].summary.terms[3])},on:{"click":_vm.openTermsAndConditions}})]:_vm._e(),(_vm.showSupportText)?[_c('span',{staticClass:"small",domProps:{"innerHTML":_vm._s(
                _vm.$translations.appointments['appointment-details'].support[0]
              )}}),_c('span',{staticClass:"link small text-purple cursor-pointer",domProps:{"innerHTML":_vm._s(
                _vm.$translations.appointments['appointment-details'].support[1]
              )},on:{"click":_vm.openKustomer}}),_c('span',{staticClass:"small",domProps:{"innerHTML":_vm._s(
                _vm.$translations.appointments['appointment-details'].support[2]
              )}})]:_vm._e(),(_vm.appointment.status === 'PAYMENT_DECLINED')?[_c('span',{staticClass:"small",domProps:{"innerHTML":_vm._s(
                _vm.$translations.appointments['appointment-details'][
                  'payment-declined'
                ][0]
              )}}),_c('span',{staticClass:"link small text-purple cursor-pointer",domProps:{"innerHTML":_vm._s(
                _vm.$translations.appointments['appointment-details'][
                  'payment-declined'
                ][1]
              )},on:{"click":_vm.openKustomer}}),_c('span',{staticClass:"small",domProps:{"innerHTML":_vm._s(
                _vm.$translations.appointments['appointment-details'][
                  'payment-declined'
                ][2]
              )}})]:_vm._e()],2)],1)]):_vm._e(),(_vm.appointment?.status === 'COMPLETED' && _vm.loading.reviews === false)?_c('ReviewSection',{attrs:{"appointment":_vm.appointment,"reviews":_vm.reviews}}):_vm._e(),(_vm.$route.query.origin !== 'shared-account')?_c('AppointmentInstrumentSection',{attrs:{"lang":_vm.$store.getters.language.lang,"route":_vm.$route,"router":_vm.$router,"appointment-id":_vm.$route.params.id}}):_vm._e(),(_vm.task)?_c('div',{staticClass:"page-segment margin-top-sm"},[_c('div',{staticClass:"page-segment-content"},[_c('Heading',{attrs:{"text":_vm.$translations.tasks.title}}),_c('div',{staticClass:"body margin-top word-wrap",domProps:{"innerHTML":_vm._s(_vm.task.content)}})],1)]):_vm._e(),(_vm.previousTasks && _vm.previousTasks.length)?_c('div',{staticClass:"page-segment margin-top-sm"},[_c('div',{staticClass:"page-segment-content"},[_c('Heading',{attrs:{"text":_vm.$translations.tasks.previous}}),_vm._l((_vm.previousTasks),function(item,key){return _c('div',{key:key,staticClass:"margin-top"},[_c('div',{staticClass:"body-big margin-bottom",domProps:{"innerHTML":_vm._s(_vm.$moment(item.created_at).format('dddd, MMM DD'))}}),_c('div',{staticClass:"body word-wrap",domProps:{"innerHTML":_vm._s(item.content)}})])})],2)]):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="page-segment margin-top-sm">
    <div class="page-segment-content">
      <!-- section title -->
      <Heading :text="$translations.reviews['section-title']" />

      <div v-if="reviews?.length" class="section margin-top-lg reviews">
        <div class="icon-star-off star-icon" />
        <div>
          <div class="section" v-for="(item, index) in sortedReviews" :key="index">
            <div
              class="body-small"
              v-text="
                item.reference_type === 'SPECIALIST' ?
                $translations.reviews['section-specialist'] :
                $translations.reviews['section-video']
              "
            />
            <div class="stars-container">
              <div
                v-for="i in 5"
                :class="starClass(item.rating, i)"
                :key="`${item.reference_type}-${i}`"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!reviews?.length"
        v-selectable-container="{ callback: navigateAppointmentReview }"
        class="section margin-top-lg"
      >
        <div class="icon-star-off star-icon" />
        <HtmlViewer class="body-small word-wrap" :html-content="$translations.reviews['section-description']" />
        <div class="icon-display icon-arrow-big-outline-right" />
      </div>
    </div>
  </div>
</template>

<script>
import { Heading, selectableContainer, HtmlViewer } from '@seliaco/red-panda'
export default {
  name: 'ReviewSection',
  props: {
    appointment: Object,
    reviews: Array
  },
  directives: { selectableContainer },
  components: { Heading, HtmlViewer },
  methods: {
    navigateAppointmentReview () {
      this.$router.push({
        name: 'AppointmentReview',
        params: {
          id: this.appointment.id
        }
      })
    }
  },
  computed: {
    starClass () {
      return (rating, index) => {
        return index <= rating ? 'icon-star-on' : 'icon-star-off'
      }
    },
    sortedReviews () {
      return this.reviews.slice().sort((a, b) => {
        if (a.reference_type === 'SPECIALIST') return -1
        if (b.reference_type === 'SPECIALIST') return 1
        return 0
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.section
  display: flex
  gap: 8px
  align-items: center
.stars-container
  display: flex
  gap: 4px
.star-icon
  display: grid
  background: var(--gray-10)
  border-radius: 16px
  color: var(--gray-50)!important
  padding: 14px 0
  text-align: center
  align-self: center
  align-content: center
  font-size: 24px
  min-width: 48px
  height: 48px
.icon-star-on,
.icon-star-off
  color: var(--yellow)
</style>

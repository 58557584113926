<template>
  <PageContainer
    :title="$translations.appointments['appointment-details'].title"
    :back="actions.back"
  >
    <template v-slot:content>
      <!-- appointment detail skeleton -->
      <div class="page-segment" v-if="loading.appointment">
        <div class="page-segment-content">
          <!-- title -->
          <Skeleton width="100px" height="19.2px" borderRadius="16px" />

          <!-- specialist card -->
          <Skeleton
            margin="16px 0"
            width="100%"
            height="96px"
            borderRadius="16px"
          />
        </div>
      </div>

      <!-- appointment detail -->
      <div class="page-segment" v-if="!loading.appointment && appointment">
        <div class="page-segment-content">
          <!-- specialist -->
          <Heading :text="$translations['appointment-summary'].specialist" />

          <!-- specialist card -->
          <PersonCard
            v-if="appointment.service.service_type.type === 'ORIENTATION'"
            class="margin-y"
            :title="specialistNameText"
            :image="appointment.specialist.user.profile_picture"
            :middle-line="appointment.specialist.type.name"
            :bottom-line="specialistAddressText"
          />
          <PersonCard
            v-else
            v-selectable-container="{
              callback: navigateToSpecialistProfile,
              disable: $route.query.origin === 'shared-account'
            }"
            class="margin-y"
            :title="specialistNameText"
            :image="appointment.specialist.user.profile_picture"
            :middle-line="appointment.specialist.type.name"
            :bottom-line="specialistAddressText"
            :show-action="$route.query.origin !== 'shared-account'"
          />

          <!-- detail -->
          <Heading :text="$translations['appointment-summary'].detail" />

          <!-- service and status -->
          <div class="appointment-service-card">
            <!-- service icon -->
            <div
              :style="{
                background: serviceIcon.background,
                color: serviceIcon.color
              }"
              class="appointment-service-card-icon"
              :class="serviceIcon.iconName"
            />

            <!-- service name -->
            <div
              class="appointment-service-card-title heading-small"
              v-text="appointment.service.service_type.name"
            />

            <!-- appointment status -->
            <div
              class="appointment-service-card-status content-title"
              :class="{
                'appointment-service-card-status-danger':
                  mainAction[appointment.status].redStatus
              }"
              v-text="$translations.status[status]"
            />
          </div>

          <!-- appointment details -->
          <div class="appointment-stats">
            <StatItem
              :text="date.day"
              :title="date.fullDate"
              :subTitle="date.time"
            />

            <StatItem
              icon="icon-timer-off"
              :title="$translations.form.duration"
              :subTitle="`${appointment.service.service_type.duration} min`"
            />

            <StatItem
              icon="icon-video-outline-off"
              :title="$translations.form['service-type']"
              :subTitle="
                $translations.appointments.virtual[appointment.virtual]
              "
            />

            <StatItem
              icon="icon-money-off"
              :title="$translations.form.price"
              :subTitle="price"
              :extraData="label"
              v-if="allowedByRole([roleType.USER, roleType.USER_B2B])"
            />
          </div>

          <!-- actions -->
          <div class="appointment-actions">
            <Button
              v-if="showMainAction"
              :text="mainAction[appointment.status].text"
              :disable="disable"
              :loading="appointment.status === 'PROCESSING_PAYMENT'"
              :icon="mainAction[appointment.status].icon"
              @clicked="handleMainAction(mainAction[appointment.status].action)"
            />

            <div
              class="appointment-actions-secondary"
              v-if="showRescheduleAndCancel"
            >
              <Button
                :text="$translations.buttons.reschedule"
                :disable="rescheduleDisabled"
                type="outline"
                color="gray"
                icon="icon-timer-calendar-off"
                @clicked="handleReschedule"
              />

              <Button
                :text="$translations.buttons.cancel"
                :disable="cancelDisabled"
                type="outline"
                color="red"
                @clicked="handleCancel"
              />
            </div>
          </div>

          <!-- hints -->
          <div class="hint">
            <!-- one day left -->
            <template v-if="showHint">
              <span
                class="small"
                v-html="
                  $translations.appointments['appointment-details'].terms[0]
                "
              />
              <span
                @click="openTermsAndConditions"
                class="link small text-purple cursor-pointer"
                v-html="
                  $translations.appointments['appointment-details'].terms[1]
                "
              />
            </template>

            <!-- can cancel -->
            <template v-if="showCanCancel">
              <span
                class="small"
                v-html="
                  $translations.appointments['appointment-details'][
                    'can-cancel'
                  ]
                "
              />
            </template>

            <!-- call supporting text -->
            <template v-if="showCallSupport">
              <span
                class="small"
                v-html="
                  $translations.appointments['appointment-details'][
                    'call-support'
                  ][0]
                "
              />
              <span
                @click="openKustomer"
                class="link small text-purple cursor-pointer"
                v-html="
                  $translations.appointments['appointment-details'][
                    'call-support'
                  ][1]
                "
              />
            </template>

            <!-- terms and conditions -->
            <template v-if="showTermsAndConditions">
              <span
                class="small"
                v-html="$translations['appointment-pages'].summary.terms[0]"
              />
              <span
                @click="openTermsAndConditions"
                class="link small text-purple cursor-pointer"
                v-html="$translations['appointment-pages'].summary.terms[1]"
              />
              <span
                class="small"
                v-html="$translations['appointment-pages'].summary.terms[2]"
              />
              <span
                @click="openTermsAndConditions"
                class="link small text-purple cursor-pointer"
                v-html="$translations['appointment-pages'].summary.terms[3]"
              />
            </template>

            <!-- call support text -->
            <template v-if="showSupportText">
              <span
                class="small"
                v-html="
                  $translations.appointments['appointment-details'].support[0]
                "
              />
              <span
                @click="openKustomer"
                class="link small text-purple cursor-pointer"
                v-html="
                  $translations.appointments['appointment-details'].support[1]
                "
              />
              <span
                class="small"
                v-html="
                  $translations.appointments['appointment-details'].support[2]
                "
              />
            </template>

            <!-- payment support text -->
            <template v-if="appointment.status === 'PAYMENT_DECLINED'">
              <span
                class="small"
                v-html="
                  $translations.appointments['appointment-details'][
                    'payment-declined'
                  ][0]
                "
              />
              <span
                @click="openKustomer"
                class="link small text-purple cursor-pointer"
                v-html="
                  $translations.appointments['appointment-details'][
                    'payment-declined'
                  ][1]
                "
              />
              <span
                class="small"
                v-html="
                  $translations.appointments['appointment-details'][
                    'payment-declined'
                  ][2]
                "
              />
            </template>
          </div>
        </div>
      </div>

      <!-- appointment review -->
      <ReviewSection
        v-if="appointment?.status === 'COMPLETED' && loading.reviews === false"
        :appointment="appointment"
        :reviews="reviews"
      />

      <AppointmentInstrumentSection
        :lang="$store.getters.language.lang"
        :route="$route"
        :router="$router"
        :appointment-id="$route.params.id"
        v-if="$route.query.origin !== 'shared-account'"
      />

      <!-- appointment task -->
      <div class="page-segment margin-top-sm" v-if="task">
        <div class="page-segment-content">
          <Heading :text="$translations.tasks.title" />

          <div class="body margin-top word-wrap" v-html="task.content" />
        </div>
      </div>

      <!-- previous appointment tasks -->
      <div
        class="page-segment margin-top-sm"
        v-if="previousTasks && previousTasks.length"
      >
        <div class="page-segment-content">
          <Heading :text="$translations.tasks.previous" />

          <div
            class="margin-top"
            v-for="(item, key) in previousTasks"
            :key="key"
          >
            <div
              class="body-big margin-bottom"
              v-html="$moment(item.created_at).format('dddd, MMM DD')"
            />
            <div class="body word-wrap" v-html="item.content" />
          </div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import {
  Appointments,
  PageContainer,
  Heading,
  PersonCard,
  ServiceIcons,
  Button,
  Skeleton,
  StatItem,
  selectableContainer,
  priceAppointment,
  labelAppointment,
  RoleMixin,
  Flow,
  AppointmentInstrumentSection,
  SharedAccountAppointment,
  Reviews
} from '@seliaco/red-panda'
import AppointmentDetailMixin from '@/mixins/appointment-detail-mixin'
import { BookingAndAppointmentDetailEvent } from '@/types/events'
import { mapGetters } from 'vuex'
import { Browser } from '@capacitor/browser'
import ReviewSection from "@/views/appointments/components/ReviewSection.vue";

export default {
  name: 'Appointment',
  mixins: [AppointmentDetailMixin, RoleMixin],
  components: {
    ReviewSection,
    PageContainer,
    Heading,
    PersonCard,
    Button,
    Skeleton,
    StatItem,
    AppointmentInstrumentSection
  },
  directives: {
    selectableContainer
  },
  data () {
    const loading = {
      appointment: false,
      task: false,
      previousTasks: false,
      price: null,
      label: null,
      day: null,
      fullDate: null,
      time: null,
      reviews: null
    }

    const actions = {
      back: {
        callback: () => {
          if (this.$route.query.back) {
            this.$router.replace({
              path: this.$route.query.back
            })
          } else {
            this.$router.push({
              name: 'Agenda'
            })
          }
        }
      }
    }

    const mainAction = {
      PENDING: {
        text: this.$translations['appointment-actions'].PENDING,
        action: 'pay'
      },
      PROCESSING_PAYMENT: {
        text: this.$translations['appointment-actions'].PROCESSING_PAYMENT
      },
      PAYMENT_DECLINED: {
        text: this.$translations['appointment-actions'].PAYMENT_DECLINED,
        action: 'pay'
      },
      CONFIRM: {
        text: this.$translations['appointment-actions'].CONFIRM,
        action: 'attend'
      },
      IN_WAITING_ROOM: {
        text: this.$translations['appointment-actions'].CONFIRM,
        action: 'attend'
      },
      IN_PROGRESS_WAITING: {
        text: this.$translations['appointment-actions'].CONFIRM,
        action: 'attend'
      },
      IN_PROGRESS: {
        text: this.$translations['appointment-actions'].CONFIRM,
        action: 'attend'
      },
      COMPLETED: {
        text: this.$translations['appointment-actions'].COMPLETED,
        action: 'schedule'
      },
      NO_ATTEND_USER: {
        text: this.$translations['appointment-actions'].NO_ATTEND_USER,
        action: 'schedule',
        redStatus: true
      },
      NO_ATTEND_SPECIALIST: {
        text: this.$translations['appointment-actions'].NON_COMPLETED_ALT,
        action: 'reschedule',
        icon: 'icon-timer-calendar-off',
        redStatus: true
      },
      NON_COMPLETED: {
        text: this.$translations['appointment-actions'].NON_COMPLETED_ALT,
        action: 'reschedule',
        icon: 'icon-timer-calendar-off',
        redStatus: true
      },
      NO_ATTEND_USER_WITH_RESCHEDULE: {
        text: this.$translations['appointment-actions'].NON_COMPLETED,
        action: 'reschedule',
        icon: 'icon-timer-calendar-off',
        redStatus: true
      },
      CANCELED: {
        text: this.$translations['appointment-actions'].CANCELED,
        action: 'schedule',
        redStatus: true
      },
      EXPIRED: {
        text: this.$translations['appointment-actions'].EXPIRED,
        action: 'schedule',
        redStatus: true
      }
    }

    return {
      loading,
      appointment: null,
      task: null,
      previousTasks: null,
      actions,
      mainAction,
      interval: null,
      price: null,
      label: null,
      body: null,
      date: null,
      reviews: null
    }
  },
  mounted () {
    this.$globalEvent.$on('refresh/appointment', () => {
      this.getAppointment()
    })

    if (this.$store.getters['auth/user']) {
      this.getAppointment()
    } else {
      this.$store.watch(
        () => this.$store.getters['auth/user'],
        () => {
          this.getAppointment()
        }
      )
    }
  },
  destroyed () {
    clearInterval(this.interval)
  },
  methods: {
    async openTermsAndConditions () {
      await Browser.open({ url: process.env.VUE_APP_TERMS_AND_CONDITIONS_URL })
    },
    openKustomer () {
      this.$kustomer.openChat(this.$store.getters.platform)
    },
    getSegmentData (value) {
      this.body = {
        specialist_id: value.specialist.id,
        specialist_name: `${value.specialist.user.first_name} ${value.specialist.user.last_name}`,
        service: value.service.service_type.name,
        appointment_status: value.status,
        appointment_details: {
          id: value.id,
          starts_at: value.starts_at,
          transactions: value.transactions,
          reason: value.reason,
          virtual_modality: value.virtual
        }
      }
    },
    async getTask () {
      const filters = {
        key: 'MEDICAL_HISTORY',
        userId: this.appointment?.specialist?.id,
        meta: JSON.stringify({
          appointment_id: this.appointment?.id,
          patient_id: this.user.id,
          specialist: this.appointment?.specialist?.id
        })
      }

      const history = await Flow.list(filters)

      const taskfilter = history.filter((i) => i.status === 'SAVED')
      const task = taskfilter.map((i) => ({
        content: i.blocks[0].answer.task.values || '-',
        created_at: this.$moment(i.updated_at).toDate(),
        id: i.id
      }))

      if (task[0]?.id) {
        this.task = task[0]
      }

      if (task.length > 1) {
        this.previousTasks = [
          ...task.slice(1, task.length),
          ...this.previousTasks
        ]
      }
    },
    async getAppointment () {
      this.loading.appointment = true

      let appointmentRespon = null

      if (this.$route.query.origin === 'shared-account') {
        if (!this.member?.id) {
          this.onError()
        }

        appointmentRespon = await SharedAccountAppointment.get(
          this.sharedAccountId,
          this.member.id,
          this.$route.params.id
        ).catch(() => {
          this.onError()
        })
      } else {
        appointmentRespon = await Appointments.getUsrAppointmentDetail(
          this.$route.params.id
        ).catch(() => {
          this.onError()
        })
      }

      const { appointment, tasks } = appointmentRespon
      this.appointment = appointment
      // set loading to false
      this.loading.appointment = false

      this.task = tasks.current
      this.previousTasks = tasks.previous

      await this.getTask()

      if (this.appointment.transactions.invoice) {
        this.price = priceAppointment(
          0,
          this.appointment?.transactions[0]?.invoice,
          this.appointment?.transactions[0]?.currency.toUpperCase()
        )
      }

      if (this.appointment.service.price) {
        this.price = priceAppointment(
          this.appointment.service.price,
          null,
          this.appointment?.service.currency.toUpperCase()
        )
      }

      this.label = labelAppointment(
        this.appointment?.user_package_id,
        this.appointment.transactions,
        this.$translations
      )

      const date = this.$moment(this.appointment.starts_at).tz(
        this.$store.getters.userTimezone
      )

      this.date = {
        day: date.format('ddd'),
        fullDate: date.format('D MMM, YYYY'),
        time: date.format('hh:mm a')
      }

      this.$router.replace({
        query: {
          ...this.$route.query,
          serviceType: this.appointment.service.service_type.type
        }
      })

      this.getSegmentData(this.appointment)

      this.reviews = await Reviews.getAppointmentReviews(this.appointment.id)
        .then(res => res?.data)
        .finally(() => {
          this.loading.reviews = false
        })
    },
    onError () {
      this.$toast({
        severity: 'error',
        text: 'No fue posible obtener la cita'
      })
      if (this.$route.query.back) {
        this.$router.replace({
          path: this.$route.query.back
        })
      } else {
        this.$router.push({
          name: 'Home'
        })
      }
    },
    navigateToSpecialistProfile () {
      const body = {
        user: this.$store.getters['auth/user'],
        origin: this.$route.name,
        specialist_id: this.appointment.specialist.id,
        specialist_name: `${this.appointment.specialist.user.first_name} ${this.appointment.specialist.user.last_name}`,
        specialist: this.appointment.specialist,
        component: 'Person card',
        is_public: this.appointment.specialist.is_public
      }
      BookingAndAppointmentDetailEvent.Select_View_Specialist(body)

      this.$router.push({
        name: 'SpecialistDetail',
        params: {
          id: this.appointment.specialist.id
        },
        query: {
          back: this.$route.fullPath
        }
      })
    },
    handleMainAction (action) {
      const option = this.mainAction[this.appointment.status].text
      this.body = { ...this.body, option }
      BookingAndAppointmentDetailEvent.ApptDetails_Click(this.body)
      switch (action) {
        case 'pay':
          return this.$router.push({
            name: 'CheckOut',
            params: {
              id: this.appointment.id,
              type: 'APPOINTMENT'
            },
            query: {
              serviceType: this.appointment.service.service_type.type,
              origin: this.sharedAccountId ? 'shared-account' : '',
              pay: true
            }
          })
        case 'attend':
          return this.$router.push({
            name: 'Lobby',
            params: {
              id: this.appointment.id
            }
          })
        case 'schedule':
          return this.$router.push({
            name: 'Schedule',
            query: {
              specialistId: this.appointment.specialist.id,
              back: this.$route.fullPath
            }
          })
        case 'reschedule':
          return this.handleReschedule()
      }
    },
    handleReschedule () {
      const option = this.$translations.buttons.reschedule
      this.body = { ...this.body, option }
      BookingAndAppointmentDetailEvent.ApptDetails_Click(this.body)
      this.$globalEvent.$emit('modal/reschedule', {
        showDialog: {
          reschedule: {
            open: true,
            close: false
          }
        },
        appointment: this.appointment
      })
    },
    handleCancel () {
      const option = this.$translations.buttons.cancel
      this.body = { ...this.body, option }
      BookingAndAppointmentDetailEvent.ApptDetails_Click(this.body)

      this.$globalEvent.$emit('modal/cancel', {
        showDialog: {
          cancel: {
            open: true,
            close: false
          }
        },
        appointment: {
          ...this.appointment,
          serviceType: this.appointment.service.service_type.type,
          sharedAccountId: this.sharedAccountId,
          sharedAccountMemberId: this.member?.id
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      role: 'auth/role',
      user: 'auth/user',
      member: 'sharedAccount/member',
      userMember: 'sharedAccount/user',
      sharedAccountId: 'sharedAccount/sharedAccountId'
    }),
    specialistNameText () {
      return `${this.appointment.specialist.user.first_name} ${this.appointment.specialist.user.last_name}`
    },
    specialistAddressText () {
      const address = this.appointment?.specialist?.user?.address
      return address.city && address.country
        ? `${address.city}, ${address.country}`
        : ''
    },
    serviceIcon () {
      return ServiceIcons[this.appointment.service.service_type.icon_name]
    },
    disable () {
      const { status } = this.appointment
      const preAllowedStatus = [
        'IN_WAITING_ROOM',
        'IN_PROGRESS_WAITING',
        'IN_PROGRESS',
        'PENDING',
        'COMPLETED',
        'CANCELED',
        'EXPIRED',
        'PROCESSING_PAYMENT',
        'PAYMENT_DECLINED',
        'NO_ATTEND_USER',
        'NO_ATTEND_SPECIALIST',
        'NON_COMPLETED',
        'CANCELED',
        'NO_ATTEND_USER_WITH_RESCHEDULE'
      ]

      return !(
        preAllowedStatus.includes(status) ||
        (this.isAboutToStart() && status === 'CONFIRM')
      )
    },
    showMainAction () {
      if (
        this.$route.query.origin === 'shared-account' &&
        this.userMember.role === 'INFANT'
      ) {
        return false
      }

      const { appointment } = this
      const { specialist, virtual, status } = appointment

      if (specialist.type.code === 'COUNSELING') {
        return false
      }

      const allowedStatus = [
        'PENDING',
        'EXPIRED',
        'PROCESSING_PAYMENT',
        'PAYMENT_DECLINED',
        'COMPLETED',
        'NO_ATTEND_USER',
        'NO_ATTEND_SPECIALIST',
        'NON_COMPLETED',
        'CANCELED',
        'NO_ATTEND_USER_WITH_RESCHEDULE'
      ]

      return virtual || allowedStatus.includes(status)
    },
    rescheduleDisabled () {
      const {
        appointment,
        orientationDisableButtons,
        oneDayLeft,
        isWithin24Hours
      } = this
      const { service, status } = appointment
      const allowedStatus = [
        'PROCESSING_PAYMENT',
        'PAYMENT_DECLINED',
        'CONFIRM',
        'PENDING'
      ]

      if (status === 'PENDING' && isWithin24Hours) {
        return false
      }

      if (service.service_type.type === 'ORIENTATION') {
        return !!orientationDisableButtons
      }

      return oneDayLeft || !allowedStatus.includes(status)
    },
    cancelDisabled () {
      const {
        appointment,
        orientationDisableButtons,
        oneDayLeft,
        isWithin24Hours
      } = this
      const { service, status } = appointment
      const disallowedStatus = [
        'CONFIRM',
        'PROCESSING_PAYMENT',
        'IN_WAITING_ROOM',
        'IN_PROGRESS_WAITING',
        'IN_PROGRESS'
      ]

      if (status === 'PENDING' && isWithin24Hours) {
        return false
      }

      if (service.service_type.type === 'ORIENTATION') {
        return !!orientationDisableButtons
      }

      return oneDayLeft || disallowedStatus.includes(status)
    },
    showRescheduleAndCancel () {
      const disallowedStatus = [
        'COMPLETED',
        'NO_ATTEND_USER',
        'NO_ATTEND_SPECIALIST',
        'NON_COMPLETED',
        'CANCELED',
        'EXPIRED',
        'NO_ATTEND_USER_WITH_RESCHEDULE'
      ]

      return !disallowedStatus.includes(this.appointment.status)
    },
    orientationDisableButtons () {
      return this.$moment().isAfter(this.appointment.starts_at)
    },
    showTermsAndConditions () {
      const status = ['COMPLETED', 'NO_ATTEND_USER', 'CANCELED']

      return status.includes(this.appointment.status)
    },
    showSupportText () {
      const status = [
        'NON_COMPLETED',
        'NO_ATTEND_SPECIALIST',
        'NO_ATTEND_USER_WITH_RESCHEDULE'
      ]

      return status.includes(this.appointment.status)
    },
    showCallSupport () {
      const statusAllowed = [
        'IN_WAITING_ROOM',
        'IN_PROGRESS_WAITING',
        'IN_PROGRESS'
      ]

      if (this.isAboutToStart()) {
        statusAllowed.push('CONFIRM')
      }

      return statusAllowed.includes(this.appointment.status)
    },
    showHint () {
      // if (this.appointment.service.service_type.type === 'ORIENTATION') {
      //   return false
      // }

      const allowedStatus = ['CONFIRM']

      if (this.showCallSupport) {
        return false
      }

      return allowedStatus.includes(this.appointment.status)
    },
    showCanCancel () {
      const status = ['PENDING', 'PROCESSING_PAYMENT']

      return status.includes(this.appointment.status)
    },
    oneDayLeft () {
      const { duration } = this.appointment.service

      const before = this.$moment(this.appointment.starts_at).subtract(1, 'day')
      const after = this.$moment(this.appointment.starts_at).add(
        duration,
        'minutes'
      )

      return this.$moment().isBetween(before, after)
    },
    isWithin24Hours () {
      const createdDateTime = this.$moment(
        this.appointment.created_at
      ).valueOf()
      const createdDateTime24hs = this.$moment(this.appointment.created_at)
        .add(24, 'hours')
        .valueOf()
      const startDateTime = this.$moment(this.appointment.starts_at).valueOf()

      return (
        startDateTime > createdDateTime && startDateTime <= createdDateTime24hs
      )
    }
  }
}
</script>

<style lang="sass" scoped>
.appointment-service-card
  display: grid
  grid-template-columns: 48px 1fr
  grid-template-rows: repeat(2, 24px)
  gap: 4px 16px
  margin-top: 28.5px
  margin-bottom: 24px

  &-icon
    grid-column: 1
    grid-row: 1 / 3
    border-radius: 9999px
    padding: 12px
    background: var(--gray-20)
    font-size: var(--xl-3)
    height: 48px

  &-title,
  &-status
    grid-column: 2

  &-title
    grid-row: 1
    color: var(--gray-80)
    align-self: end

  &-status
    grid-row: 2
    color: var(--gray-60)
    align-self: start

.appointment-stats
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 30px 16px

.appointment-actions
  display: grid
  gap: 16px
  margin-top: 32px

  &-secondary
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 16px

.appointment-service-card-status-danger
  color: var(--red)

.hint
  margin: 32px 0 24px
  text-align: center
  line-height: 15px

.word-wrap
  overflow-wrap: break-word
  word-break: break-word
</style>
